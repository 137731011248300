import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function Status(props) {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState("Business Admin");

  function getBusinessName() {
    let bsDetails = JSON.parse(localStorage.getItem("business"));
    let businessName = bsDetails.business_name || "Business Admin";
    setBusinessName(businessName);
  }

  const [loading, setLoading] = React.useState(false);

  const handleLoading = () => {
    setLoading(!loading);
  };

  useEffect(() => {
    setLoading(true);
    getBusinessName();
    setLoading(false);
    let clearInfo = setTimeout(() => {
      localStorage.removeItem("candidate");
      localStorage.removeItem("candidate_token");
      // localStorage.removeItem("business");
      localStorage.removeItem("business_logo");
      // localStorage.removeItem("base64_video");
      localStorage.removeItem("recording");
      window.location.href = "/#/candidate-login";
      // navigate('/candidate-login');
    }, 5000);

    return () => {
      clearTimeout(clearInfo);
    };
  }, []);

  return (
    <Container maxWidth="lg">
      {loading ? (
        <Box style={{ textAlign: "center" }}>
          <CircularProgress sx={{ mt: 1 }} disableShrink />
        </Box>
      ) : (
        <Box
          className="middle-section"
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            outlined={0}
            elevation={0}
            sx={{ width: "100%", backgroundColor: "transparent" }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ textAlign: "center" }}>
                {/* <img src={emailSent} alt="email sent"/> */}
                {/* <lottie-player src={lottieJson}  background="transparent"  speed="1"   style={{width: "100%", height: "100%"}} loop autoplay></lottie-player> */}
                <lottie-player
                  src="https://assets9.lottiefiles.com/packages/lf20_nxkmi9um.json"
                  background="transparent"
                  speed="1"
                  style={{ width: 200, height: 200, margin: "0 auto" }}
                  loop
                  autoplay
                ></lottie-player>
              </Box>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontWeight: 700, mt: 1 }}
              >
                Thank You
              </Typography>
              {businessName && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontWeight: 700, mt: 1 }}
                >
                  Your Video Profile is Ready and sent to{" "}
                  {businessName || "Business Admin!"}!
                </Typography>
              )}
              {/* <Typography variant="body1" component="p" sx={{ fontSize: '1.125rem', textAlign: 'center', mt: 1 }}>
                We will notify you on your email once the video is ready.
              </Typography> */}
            </CardContent>
          </Card>
        </Box>
      )}
    </Container>
  );
}

export default Status;
