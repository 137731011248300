import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Stack,
  Container,
  Backdrop,
  CircularProgress,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { uploadVideoApi } from "../../apis";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import GameLayout from "../GameLayout";

function PreviewUpload(props) {
  const [progress, setProgress] = useState(0);
  const timer = React.useRef();
  const videoRef = React.useRef();

  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isAlert, setIsAlert] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const [srcVid, setSrcVid] = useState("");
  const [showBtn, setShowBtn] = useState(false);

  const [posterImg, setPosterImage] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    setLoadVideo(true);
    let videoURL = JSON.parse(localStorage.getItem("recording"));
    setTimeout(() => {
      setSrcVid(videoURL);
    }, 3000);
    if (videoURL !== null) {
      setLoadVideo(false);
    }
    // alert("Video URL: " + videoURL);
    if (srcVid !== null) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }

    setLoading(false);

    setTimeout(function () {
      setIsAlert(false);
      setIsError(false);
    }, 5000);
  }, [isAlert, srcVid]);

  useEffect(() => {
    let videoBg = JSON.parse(localStorage.getItem("business"));

    const PosterImg = videoBg
      ? process.env.REACT_APP_BUSINESS_LOGO_API + videoBg?.video_template
      : "http://dummyimage.com/320x240/ffffff/fff";

    setPosterImage(PosterImg);
  }, []);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <br />
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  async function continueNext() {
    let resp = await fetch(srcVid);
    let blob = await resp.blob();
    var file = new File([blob], "myVideo", { type: "video/mp4" });
    setLoading(true);
    let formData = new FormData();
    console.log("uploaded file object ", file);
    formData.append("video_url", file);
    let payload = formData;
    const progressList = [80, 83, 86, 89, 90, 93, 94, 95, 96, 99];
    let randVal = progressList[Math.floor(Math.random() * progressList.length)];
    console.log("RANVAL", randVal);
    timer.current = setInterval(() => {
      setProgress((prevProgress) => {
        if (randVal <= prevProgress) {
          clearInterval(timer.current);
          return prevProgress;
        } else {
          return prevProgress + 5;
        }
      });
    }, 2000);

    let vidResp = await uploadVideoApi(payload);
    let vidUploadStatus = vidResp.status;

    if (vidUploadStatus === 200) {
      timer.current = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer.current);
            return prevProgress;
          } else {
            return prevProgress + 1;
          }
        });
      }, 1000);

      setIsAlert(true);
      let errMessage = vidResp.data.message || "Video Uploaded Successfully";
      setAlertMessage(errMessage);
      props.uploadVideo();
    } else {
      setIsAlert(true);
      setIsError(true);
      let errMessage =
        vidResp.data.message || "Something went wrong, Please try again";
      setAlertMessage(errMessage);
    }
    setLoading(false);
  }
  function previous() {
    localStorage.removeItem("recording");
    // localStorage.removeItem('base64_video');
    props.retakeVideo();
  }

  const handleLoading = () => {
    setLoading(!loading);
  };

  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAlert(false);
    setIsError(false);
  };
  //Dailog box
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleEndVideo = () => {
    // console.log((videoRef.current.poster = posterImg));
    var vid = videoRef.current;

    vid.load();
  };
  return (
    <Container maxWidth="lg">
      {loading ? (
        // <Backdrop
        //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //   open={loading}
        //   onClick={handleLoading}
        // >
        //   <CircularProgress color="inherit" />
        // </Backdrop>
        <>
          {/* <Typography variant="h5" sx={{ textAlign: "center", fontWeight: 700 }}>
       {"Processing... please wait...   :)"}
      </Typography>
     
        <LinearProgressWithLabel value={progress} /> */}

          <Box
            className="middle-section"
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              className="custom-card"
              variant="outlined"
              sx={{ width: "100%" }}
            >
              <CardContent sx={{ p: 3 }}>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", fontWeight: 700 }}
                >
                  {"Processing... please wait...   :)"}
                </Typography>
                {/* <Typography
                  // variant="p"
                  sx={{ textAlign: "center" }}
                >
                  {"If you are bored..."}

                  <Button
                    onClick={handleClickOpen}
                    style={{
                      color: "black",
                      textTransform: "capitalize",
                      fontWeight: 700,
                      textDecoration: "underline",
                    }}
                  >
                    Play Little Game
                  </Button>
                </Typography> */}

                {isAlert && (
                  <Alert
                    open={isAlert}
                    variant="filled"
                    onClose={handleAlert}
                    severity={isError ? "error" : "success"}
                  >
                    {alertMessage}
                  </Alert>
                )}
                <LinearProgressWithLabel value={progress} />
              </CardContent>
            </Card>
          </Box>
        </>
      ) : (
        <Box
          className="middle-section"
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            className="custom-card"
            variant="outlined"
            sx={{ width: "100%" }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography
                variant="h5"
                sx={{ textAlign: "center", fontWeight: 700 }}
              >
                Your Video is Ready to Process
              </Typography>
              {isAlert && (
                <Alert
                  open={isAlert}
                  variant="filled"
                  onClose={handleAlert}
                  severity={isError ? "error" : "success"}
                >
                  {alertMessage}
                </Alert>
              )}
              {loadVideo === false ? (
                <Container maxWidth="sm">
                  <Box sx={{ mt: 3 }}>
                    <video
                      id="myVid"
                      width="100%"
                      ref={videoRef}
                      height="100%"
                      poster={posterImg}
                      playsInline
                      // src={
                      //   srcVid != null
                      //     ? srcVid
                      //     : "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"
                      // }
                      src={srcVid}
                      onEnded={() => handleEndVideo()}
                      controls
                      style={{ overflow: "hidden" }}
                    ></video>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: "center" }}>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      <Button
                        variant="text"
                        color="secondary"
                        size="large"
                        sx={{ textTransform: "inherit" }}
                        onClick={previous}
                      >
                        Retake Video
                      </Button>
                      {showBtn === true && (
                        <Button
                          variant="text"
                          color="primary"
                          size="large"
                          sx={{ textTransform: "inherit" }}
                          onClick={continueNext}
                        >
                          Upload Video
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Container>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="inherit" />
                </div>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tic-Tac Game</DialogTitle>
        <DialogContent>
          <GameLayout />
        </DialogContent>
      </Dialog> */}
    </Container>
  );
}

export default PreviewUpload;
