import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo.png";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Typography,
  useMediaQuery,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";

function CandidateVideo() {
  const [loading, setLoading] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [posterImage, setPosterImage] = useState(null);
  const videoRef = React.useRef();

  // media query
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const fontSizeSm = {
    ...(sm && {
      fontSize: "1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }),
  };

  const handleLoading = () => {
    setLoading(!loading);
  };

  const [title, setTitle] = useState("eSoft");

  useEffect(() => {
    setLoading(true);
    let pathArr = window.location.href.split("/");
    let id = pathArr[pathArr.length - 1];

    // get business_name from url param
    let params = window.location.hash.split("?")[1];
    let business_name = decodeURI(params.split("=")[1]);
    if (business_name && business_name.length > 0) {
      setTitle(business_name);
    }

    setVideoId(id);
    setLoading(false);
  }, [window.location]);
  useEffect(() => {
    let videoBg = JSON.parse(localStorage.getItem("business"));

    const PosterImg = videoBg
      ? process.env.REACT_APP_BUSINESS_LOGO_API + videoBg?.video_template
      : "http://dummyimage.com/320x240/ffffff/fff";

    setPosterImage(PosterImg);
  }, []);
  const handleEndVideo = () => {
    var vid = videoRef.current;
    vid.load();
  };

  return (
    <>
      <AppBar
        position="sticky"
        color="default"
        className="header"
        elevation={0}
      >
        <Container maxWidth="false">
          <Toolbar disableGutters variant="dense">
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box className="logo">
                <Link to="#">
                  <img src={logo} alt="" />
                </Link>
              </Box>
              <Box className="company-logo">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 500, ...fontSizeSm }}
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ pt: 3, px: 3 }}>
        {videoId == "" ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={handleLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Container maxWidth="lg" sx={{ px: "0 !important" }}>
            <Grid container spacing={0}>
              <Grid item xs={12} className="video-screen">
                <video
                  width="100%"
                  height="100%"
                  ref={videoRef}
                  controls
                  playsInline
                  controlsList="nodownload"
                  poster={posterImage}
                  onEnded={() => handleEndVideo()}
                  src={
                    videoId != null
                      ? process.env.REACT_APP_VIDEO_PATH + videoId
                      : "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"
                  }
                  style={{
                    borderRadius: "12px",
                    overflow: "hidden",
                    backgroundColor: "#000",
                  }}
                />
              </Grid>
            </Grid>
            {/* {videoId ? (
              <Grid container spacing={0}>
                <Grid item xs={12} className="video-screen">
                  <video
                    width="100%"
                    height="100%"
                    controls
                    controlsList="nodownload"
                    // poster={process.env.REACT_APP_VIDEO_PATH + videoId}
                    src={
                      videoId != null
                        ? process.env.REACT_APP_VIDEO_PATH + videoId
                        : "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"
                    }
                    type="video/mp4"
                    style={{
                      borderRadius: "12px",
                      overflow: "hidden",
                      backgroundColor: "#000",
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Invalid Candidate Video</Typography>
              </Box>
            )} */}
          </Container>
        )}
      </Box>
    </>
  );
}

export default CandidateVideo;
