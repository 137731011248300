import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/img/logo.png";
import lottieImage from "../assets/img/video-conference.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordApi } from "../apis";
import * as Yup from "yup";

import {
	Container,
	Grid,
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	FormGroup,
	TextField,
	useMediaQuery,
	Alert,
	Backdrop,
	CircularProgress,
} from "@mui/material";

function ForgotPassword() {
	const theme = useTheme();
	const navigate = useNavigate();

	//back to login click
	const BackToLogin = () => {
		navigate("/");
	};

	// form validation
	const validationSchema = Yup.object().shape({
		email: Yup.string().required("Email is required").email("Email is invalid"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const [isError, setIsError] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [isAlert, setIsAlert] = React.useState(false);

	const handleLoading = () => {
		setLoading(!loading);
	};

	const handleAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setIsAlert(false);
		setIsError(false);
	};

	const onSubmit = async (data) => {
		setLoading(true);
		let payload = {
			email: data.email,
		};
		let headers = {
			"Content-Type": "multipart/form-data",
		};
		let forgotPasswordResp = await forgotPasswordApi(payload, headers);
		let candidateListStatus = forgotPasswordResp.status;
		if (candidateListStatus === 200) {
			// store token from response
			setIsAlert(true);
			let successMessage = forgotPasswordResp.data.message || "Please check your email. Reset link sent to your email.";
			setAlertMessage(successMessage);
		} else {
			setIsAlert(true);
			setIsError(true);
			let errMessage = forgotPasswordResp.data.message || "Something went wrong, Please try again";
			setAlertMessage(errMessage);
		}
		setLoading(false);
		// navigate("/admin-set-password");
	};

	useEffect(() => {
		setTimeout(function () {
			setIsAlert(false);
			setIsError(false);
		}, 5000); //5 Second delay
	}, [isAlert]);

	// mobile media query
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<div className={`auth-screen`}>
			{/* className={`${classes.root} auth-screen`} */}
			<Container maxWidth="lg" sx={{ py: 2 }}>
				<Grid
					className="bg-container"
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={isMobile ? 2 : 2}
				>
					<Grid item xs={12} md={7} sx={{ display: { xs: "none", md: "block" } }}>
						<div className="body-bg">
							{/* <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_ihspk1vg.json"  background="transparent"  speed="1"   style={{width: "100%", height: "100%"}} loop autoplay></lottie-player> */}
							<img src={lottieImage} alt="" />
						</div>
					</Grid>
					<Grid item xs={12} md={5} sx={{ paddingLeft: "0 !important" }}>
						<Card className="custom-card" variant="outlined">
							<CardContent sx={{ padding: "0 !important" }}>
								<Box sx={{ textAlign: "center" }}>
									<img src={logo} alt="" className="logo-image" />
								</Box>
								<Typography variant="h6" sx={{ fontSize: "1.375rem", textAlign: "center" }} gutterBottom>
									Forgot Password
								</Typography>
								<Typography variant="body2" sx={{ fontWeight: 500, textAlign: "center" }}>
									Enter registered email
								</Typography>
								{isAlert && (
									<Alert variant="filled" open={isAlert} onClose={handleAlert} severity={isError ? "error" : "success"}>
										{alertMessage}
									</Alert>
								)}
								<div className="auth-form">
									<Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
										<FormGroup sx={{ mb: 4 }}>
											<TextField
												label="Email"
												placeholder="john@mail.com"
												type="email"
												{...register("email")}
												error={errors.email ? true : false}
											/>
											<Typography sx={{ mt: 0.5 }} variant="inherit" className="error">
												{errors.email?.message}
											</Typography>
										</FormGroup>
										{loading ? (
											<Box style={{ textAlign: "center" }}>
												<CircularProgress sx={{ mt: 1 }} disableShrink />
											</Box>
										) : (
											<Box>
												<Button
													variant="contained"
													disabled={loading}
													disableElevation
													size="large"
													sx={{ width: "100%" }}
													type="submit"
												>
													Continue
												</Button>
											</Box>
										)}
										<Box sx={{ mt: 1 }}>
											<Button
												variant="text"
												size="small"
												color="secondary"
												sx={{ textTransform: "inherit" }}
												onClick={BackToLogin}
											>
												Back to login
											</Button>
										</Box>
									</Box>
								</div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default ForgotPassword;
