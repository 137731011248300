import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { candidateLoginApi, getCandidateListApi } from "../apis";

import logo from "../assets/img/logo.png";
import lottieImage from "../assets/img/video-conference.svg";

import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  FormGroup,
  TextField,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";

function CandidateLogin() {
  const theme = useTheme();
  const navigate = useNavigate();

  // mobile media query
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const [otp, setOtp] = React.useState("");

  const [isError, setIsError] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);

  const [validated, setValidated] = useState(true);
  const [validationMessage, setValidationMessage] = useState(
    "Please enter valid code"
  );

  const handleLoading = () => {
    setLoading(!loading);
  };

  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAlert(false);
    setIsError(false);
  };

  const handleCodeChange = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value == "") {
      setValidationMessage("Code must be 8 digit numeric");
      setValidated(false);
      setOtp(e.target.value);
    }
    if (re.test(e.target.value) && e.target.value.length < 9) {
      setOtp(e.target.value);
      setValidated(true);
    }
  };

  const handleSubmit = async (data) => {
    if (otp == "") {
      setValidationMessage("Please enter valid code");
      setValidated(false);
      return;
    }
    if (otp.length != 8) {
      setValidationMessage("Code must be 8 digits");
      setValidated(false);
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append("unique_code", otp);
    let candidateLoginResp = await candidateLoginApi(formData);
    let candidateLoginStatus = candidateLoginResp.status;
    if (candidateLoginStatus === 200) {
      setIsAlert(true);
      let errMessage = candidateLoginResp.data.message || "Login Successful";
      setAlertMessage(errMessage);
      let candidate = candidateLoginResp.data.data.candidate;
      localStorage.setItem("candidate", JSON.stringify(candidate));
      localStorage.setItem("candidate_token", candidate.token);
      localStorage.setItem(
        "business",
        JSON.stringify(candidateLoginResp.data.data.businesses)
      );
      localStorage.setItem(
        "business_logo",
        candidateLoginResp.data.data.business.businesses.logo
      );

      navigate("/candidate-verification");
    } else {
      setIsAlert(true);
      if (candidateLoginStatus === 401) {
        navigate("/candidate-login");
      }
      setIsError(true);
      let errMessage =
        candidateLoginResp.data.message || "Login Error, Please try again";
      setAlertMessage(errMessage);
    }
    setLoading(false);
  };

  const location = useLocation();

  function checkForCandidateToken() {
    let candidateToken = localStorage.getItem("candidate_token");
    if (candidateToken) {
      navigate("/candidate-verification");
    }
  }

  React.useEffect(() => {
    // checkForCandidateToken();
    let params = location.search.slice(1);
    let paramsArr = params.split("=");
    if (paramsArr[0] == "cid") {
      let cid = paramsArr[1];
      setOtp(cid);
      // document.getElementById("otp-code").focus();
    }
    setTimeout(function () {
      setIsAlert(false);
      setIsError(false);
    }, 5000); //5 Second delay
  }, [isAlert, checkForCandidateToken]);

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className={`auth-screen`}>
          <Container maxWidth="lg" sx={{ py: 2 }}>
            <Grid
              className="bg-container"
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={isMobile ? 2 : 2}
            >
              <Grid
                item
                xs={12}
                md={7}
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <div className="body-bg">
                  {/* <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_ihspk1vg.json"  background="transparent"  speed="1"   style={{width: "100%", height: "100%"}} loop autoplay></lottie-player> */}
                  <img src={lottieImage} alt="" />
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <Card className="custom-card" variant="outlined">
                  <CardContent sx={{ padding: "0 !important" }}>
                    <Box sx={{ textAlign: "center" }}>
                      <img src={logo} alt="" className="logo-image" />
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "1.375rem", textAlign: "center" }}
                      gutterBottom
                    >
                      Candidate Login
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, textAlign: "center" }}
                    >
                      Enter 8 digit code to get started
                    </Typography>
                    {isAlert && (
                      <Alert
                        mt={2}
                        variant="filled"
                        open={isAlert}
                        onClose={handleAlert}
                        severity={isError ? "error" : "success"}
                      >
                        {alertMessage}
                      </Alert>
                    )}
                    <div className="auth-form">
                      <Box component="form" noValidate autoComplete="off">
                        <FormGroup sx={{ mb: 4 }}>
                          <TextField
                            label="Code"
                            id="otp-code"
                            placeholder="Enter Code"
                            value={otp}
                            onChange={(e) => handleCodeChange(e)}
                          />
                          {!validated && (
                            <Typography
                              variant="inherit"
                              sx={{ mt: 0.5 }}
                              className="error"
                            >
                              {validationMessage}
                            </Typography>
                          )}
                        </FormGroup>
                        <Box>
                          <Button
                            variant="contained"
                            disableElevation
                            size="large"
                            sx={{ width: "100%" }}
                            onClick={handleSubmit}
                          >
                            Continue
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
}

export default CandidateLogin;
