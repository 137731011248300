import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Circle, PlayCircle, Cancel } from "@mui/icons-material";

// camera permission
const camPermission = [
  {
    key: 1,
    description: "Allow Camera and Microphone Permissions",
  },

];

// video recording tips
const recTips = [
  {
    key: 1,
    description: "Use a Clean Background.",
  },
  {
    key: 2,
    description:
      "Choose a quiet location with little background noise (street sounds, music, other people)",
  },
  {
    key: 3,
    description: "Avoid Shaky Footage.",
  },
  {
    key: 4,
    description:
      "Find a location that is well-lit with natural sunlight or  plenty of indoor lighting",
  },
  {
    key: 5,
    description:
      "Position yourself so windows or your main light  sources are facing you, not behind you.",
  },
];

function RecordingInformation() {
  // media query
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const guideTitle = {
    ...(xs && { fontSize: "1rem" }),
    ...(sm && { fontSize: "1.125rem" }),
  };

  // sample video dialogue
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="video-guide" sx={{ height: "calc(100% + 24px)", p: 2.5 }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ ...guideTitle }}>
            Allow Video Cam Permissions
          </Typography>
        </Box>
        <List dense>
          {camPermission.map((permission, index) => (
            <ListItem key={index} sx={{ px: 0, py: 1, alignItems: "start" }}>
              <ListItemIcon sx={{ minWidth: 28, mt: 0.25 }}>
                <Circle sx={{ fontSize: "1rem" }} color="secondary" />
              </ListItemIcon>
              <ListItemText secondary={permission.description} sx={{ my: 0 }} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ ...guideTitle }}>
            Video Recoding Tips
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="text"
              sx={{ textTransform: "capitalize", whiteSpace: "nowrap" }}
              onClick={handleClickOpen}
            >
              <Box className="pulse-animation">
                <PlayCircle fontSize="large" color="secondary" />
              </Box>
              <Typography
                variant="body2"
                className="secondary-text"
                sx={{ ml: 1, fontWeight: 500 }}
              >
                Sample Video
              </Typography>
            </Button>

            {/* video popup dialogue */}

            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="md"
              className="video-dialogue"
              PaperProps={{
                elevation: 0,
                sx: { backgroundColor: "transparent", m: 0 },
              }}
            >
              <DialogTitle
                sx={{ position: "relative", textAlign: "center", p: 4 }}
              >
                Sample Video
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 24,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={handleClose}
                >
                  <Cancel fontSize="large" />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ borderRadius: "12px", height: "100%" }}>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    style={{ borderRadius: "12px", overflow: "hidden" }}
                  >
                    <source
                      src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
        <List dense>
          {recTips.map((tips, index) => (
            <ListItem key={index} sx={{ px: 0, py: 1, alignItems: "start" }}>
              <ListItemIcon sx={{ minWidth: 28, mt: 0.25 }}>
                <Circle sx={{ fontSize: "1rem" }} color="secondary" />
              </ListItemIcon>
              <ListItemText secondary={tips.description} sx={{ my: 0 }} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* <Box className="bottom-img">
                    <img src={girlWithLappy} alt="placeholder"/>
                  </Box> */}
    </Box>
  );
}

export default RecordingInformation;
