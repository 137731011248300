import React from "react";
// import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Routes, Route, Outlet } from "react-router-dom";
// import {Routes , Route, Outlet } from "react-router-dom"

import "./assets/scss/styles.scss";
import "./index.css";
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import Header from "../src/components/Header";
import Footer from "../src/components/Footer";
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import SetPassword from "./auth/SetPassword";
import CandidateList from "./candidate/List";
import ChangePassword from "./view/ChangePassword";
import CandidateLogin from "./auth/CandidateLogin";
import CandidateVerification from "./view/CandidateVerification";
import CandidateVideo from "./candidate/Video";
import GameLayout from "./candidate/GameLayout";

const HeaderLayout = () => {
  return (
    <>
      <Header />
      <div className="body-middle">
        <Outlet />
      </div>
    </>
  );
};
function App() {
  return (
    <Router>
      <div className="full-section">
        <Routes>
          <Route element={<HeaderLayout />}>
            <Route
              exact
              path="/admin-candidate-list"
              element={<CandidateList />}
            />
            <Route path="/admin-change-password" element={<ChangePassword />} />
            <Route path="/candidate-game" element={<GameLayout />} />

            <Route
              path="/candidate-verification"
              element={<CandidateVerification />}
            />
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/admin-forgot-password" element={<ForgotPassword />} />
          <Route path="/admin-set-password" element={<SetPassword />} />
          <Route path="/candidate-login" element={<CandidateLogin />} />
          <Route
            path="/candidate-video/:videoId"
            element={<CandidateVideo />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
