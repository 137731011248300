import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordApi } from "../apis";
import * as Yup from "yup";

import {
	Container,
	Grid,
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	FormGroup,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Alert,
	Backdrop,
	CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ChangePassword() {
	const navigate = useNavigate();
	//back to login click
	const loginPage = () => {
		navigate("/");
	};

	// show hide current passowrd
	const [values, setValues] = React.useState({
		password: "",
		showPassword: false,
	});
	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};
	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// show hide new passowrd
	const [valuesNewPassword, setValuesNewPassword] = React.useState({
		password: "",
		showPassword: false,
	});
	const handleChangeNewPassword = (prop) => (event) => {
		setValuesNewPassword({ ...valuesNewPassword, [prop]: event.target.value });
	};
	const handleClickShowNewPassword = () => {
		setValuesNewPassword({
			...valuesNewPassword,
			showPassword: !valuesNewPassword.showPassword,
		});
	};
	const handleMouseDownNewPassword = (event) => {
		event.preventDefault();
	};

	// show hide confirm new passowrd
	const [valuesConfirm, setValuesConfirm] = React.useState({
		password: "",
		showPassword: false,
	});
	const handleChangeConfirm = (prop) => (event) => {
		setValuesConfirm({ ...valuesConfirm, [prop]: event.target.value });
	};
	const handleClickShowPasswordConfirm = () => {
		setValuesConfirm({
			...valuesConfirm,
			showPassword: !valuesConfirm.showPassword,
		});
	};
	const handleMouseDownPasswordConfirm = (event) => {
		event.preventDefault();
	};

	// form validation
	const validationSchema = Yup.object().shape({
		currentPassword: Yup.string()
			.required("Password is required")
			.min(6, "Password must be at least 6 characters")
			.max(20, "Password must not exceed 20 characters"),
		newPassword: Yup.string()
			.required("New password is required")
			.min(6, "New password must be at least 6 characters")
			.max(20, "New password must not exceed 20 characters"),
		confirmPassword: Yup.string()
			.required("Confirm password is required")
			.oneOf([Yup.ref("newPassword"), null], "Confirm password does not match"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const [isError, setIsError] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [isAlert, setIsAlert] = React.useState(false);

	const handleLoading = () => {
		setLoading(!loading);
	};

	const handleAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setIsAlert(false);
		setIsError(false);
	};

	const onSubmit = async (data) => {
		setLoading(true);
		let formData = new FormData();
		let userId = JSON.parse(localStorage.getItem("user"));
		formData.append("id", userId.id);
		formData.append("current_password", data.currentPassword);
		formData.append("new_password", data.newPassword);
		formData.append("confirm_password", data.confirmPassword);
		let changePasswordResp = await changePasswordApi(formData);
		let changePasswordStatus = changePasswordResp.status;
		if (changePasswordStatus === 200) {
			// navigate("/");
			setIsAlert(true);
			let errMessage = changePasswordResp.data.message || "Password changed successfully";
			setAlertMessage(errMessage);
			setTimeout(() => navigate('/'), 1000);
		} else {
			setIsAlert(true);
			if (changePasswordStatus === 401) {
				navigate("/");
			}
			setIsError(true);
			let errMessage = changePasswordResp.data.message || "Error Changing Password, Please try again";
			setAlertMessage(errMessage);
		}
		setLoading(false);
	};

	useEffect(() => {
		setTimeout(function () {
			setIsAlert(false);
			setIsError(false);
		}, 5000); //5 Second delay
	}, [isAlert]);

	return (
		<>
			{loading ? (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}
					onClick={handleLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			) : (
				<div className={`auth-screen center-with-header`}>
					<Container maxWidth="lg" sx={{ py: 2 }}>
						<Grid
							className="bg-container"
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={0}
						>
							<Grid item xs={12} md={5} sx={{ paddingLeft: "0 !important" }}>
								<Card className="custom-card" variant="outlined">
									<CardContent sx={{ padding: "0 !important" }}>
										<Typography variant="h6" sx={{ fontSize: "1.375rem", textAlign: "center" }} gutterBottom>
											Change Password
										</Typography>
										{isAlert && (
											<Alert
												variant="filled"
												open={isAlert}
												onClose={handleAlert}
												severity={isError ? "error" : "success"}
											>
												{alertMessage}
											</Alert>
										)}
										<div className="auth-form">
											<Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
												<FormGroup sx={{ mb: 4 }}>
													<FormControl variant="outlined">
														<InputLabel htmlFor="currentPassword">Current Password</InputLabel>
														<OutlinedInput
															id="currentPassword"
															type={values.showPassword ? "text" : "password"}
															onChange={handleChange("password")}
															{...register("currentPassword")}
															error={errors.password ? true : false}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		onMouseDown={handleMouseDownPassword}
																		edge="end"
																		color="primary"
																	>
																		{values.showPassword ? <VisibilityOff /> : <Visibility />}
																	</IconButton>
																</InputAdornment>
															}
															label="Current Password"
														/>
													</FormControl>
													<Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
														{errors.currentPassword?.message}
													</Typography>
												</FormGroup>
												<FormGroup sx={{ mb: 4 }}>
													<FormControl variant="outlined">
														<InputLabel htmlFor="newPassword">New Password</InputLabel>
														<OutlinedInput
															id="newPassword"
															type={valuesNewPassword.showPassword ? "text" : "password"}
															onChange={handleChangeNewPassword("password")}
															{...register("newPassword")}
															error={errors.newPassword ? true : false}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowNewPassword}
																		onMouseDown={handleMouseDownNewPassword}
																		edge="end"
																		color="primary"
																	>
																		{valuesNewPassword.showPassword ? <VisibilityOff /> : <Visibility />}
																	</IconButton>
																</InputAdornment>
															}
															label="New Password"
														/>
													</FormControl>
													<Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
														{errors.newPassword?.message}
													</Typography>
												</FormGroup>
												<FormGroup sx={{ mb: 4 }}>
													<FormControl variant="outlined">
														<InputLabel htmlFor="confirmPassword">Confirm New Password</InputLabel>
														<OutlinedInput
															id="confirmPassword"
															type={valuesConfirm.showPassword ? "text" : "password"}
															onChange={handleChangeConfirm("password")}
															{...register("confirmPassword")}
															error={errors.confirmPassword ? true : false}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPasswordConfirm}
																		onMouseDown={handleMouseDownPasswordConfirm}
																		edge="end"
																		color="primary"
																	>
																		{valuesConfirm.showPassword ? <VisibilityOff /> : <Visibility />}
																	</IconButton>
																</InputAdornment>
															}
															label="Confirm New Password"
														/>
													</FormControl>
													<Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
														{errors.confirmPassword?.message}
													</Typography>
												</FormGroup>

												<Box>
													<Button
														variant="contained"
														disableElevation
														size="large"
														sx={{ width: "100%" }}
														type="submit"
													>
														Update
													</Button>
												</Box>
												{/* <Box sx={{ mt: 1 }}>
                          <Button
                            variant="text"
                            size="small"
                            color="secondary"
                            sx={{ textTransform: "inherit" }}
                            onClick={loginPage}
                          >
                            Back to login
                          </Button>
                        </Box> */}
											</Box>
										</div>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Container>
				</div>
			)}
		</>
	);
}
export default ChangePassword;
