import React, { useState, useEffect, useRef } from "react";
import { Box, Stack, Button } from "@mui/material";
import { Circle, Pause, PlayArrow, Stop } from "@mui/icons-material";

import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { Camera } from "@mediapipe/camera_utils";
import RecordRTC from "recordrtc";
import "context-filter-polyfill";
var record;

function VideoCam(props) {
  const { bg } = props;

  const inputVideoRef = useRef();
  const canvasRef = useRef();
  const contextRef = useRef();
  var rec = useRef();

  var videoStream = useRef();
  const [recStart, setRecStart] = useState(false);
  const [btnRec, setBtnRec] = useState(false);

  const [videoHeight, setVideoHeight] = useState("");
  const [videoWidth, setVideoWidth] = useState("");

  const constraints = {
    audio: { echoCancellation: true },
    video: {
      facingMode: "user",

      //width:9999
      width: window.innerWidth,
    },
  };

  useEffect(() => {
    checkWebCam();
    startCam();
  }, []);

  async function startCam() {
    contextRef.current = canvasRef.current.getContext("2d");
    //width=9999
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: { width: window.innerWidth } })
      .then(async (stream) => {
        window.localStream = stream;

        videoStream = new MediaStream(stream.getVideoTracks());

        inputVideoRef.current.srcObject = videoStream;
      });

    let { width, height } = videoStream.getTracks()[0].getSettings();

    console.log("WIDTH", width, "height", height);
    setVideoWidth(width);
    setVideoHeight(height);
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
      },
    });

    selfieSegmentation.setOptions({
      modelSelection: 1,
      selfieMode: true,
    });
    selfieSegmentation.onResults(onResults);
    // selfieSegmentation.onResults(resultData);
    setTimeout(() => {
      setBtnRec(true);
    }, 2000);

    const camera = new Camera(inputVideoRef.current, {
      onFrame: async () => {
        await selfieSegmentation.send({ image: inputVideoRef.current });
      },
      width: { ideal: window.innerWidth },
      height: { ideal: window.innerHeight },
    });
    camera.start();
  }

  async function checkWebCam() {
    try {
      navigator.getMedia =
        navigator.getUserMedia || // use the proper vendor prefix
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

      // navigator.getUserMedia = ( navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.mediaDevices.getUserMedia);

      // let flag;
      // await navigator.getMedia({ video: true }, function () {
      //   flag = true;
      // }, function () {
      //   flag = false;
      //   alert('Web Cam is not available');
      //   window.location.reload(1);
      // });

      let flag;
      //width = 9999
      var constraints = { audio: true, video: { width: window.innerWidth } };

      await navigator.mediaDevices.getUserMedia(
        constraints,
        function () {
          flag = true;
        },
        function () {
          flag = false;
          alert("Web Cam is not available");
          window.location.reload(1);
        }
      );

      return flag;
    } catch (err) {
      alert(`Error : ${err.message}`);
      window.location.reload(1);
    }
  }

  const onResults = (results) => {
    contextRef.current.save();
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    contextRef.current.filter = "none";

    contextRef.current.globalCompositeOperation = "source-out";
    contextRef.current.drawImage(
      results.segmentationMask,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    contextRef.current.globalCompositeOperation = "source-in";
    contextRef.current.drawImage(
      results.image,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    contextRef.current.globalCompositeOperation = "destination-atop";
    contextRef.current.filter = "blur(10px)";
    contextRef.current.drawImage(
      results.image,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    contextRef.current.restore();
  };

  const startRecording = async (e) => {
    setRecStart(true);
    const chunks = [];
    const stream = canvasRef.current.captureStream();
    await navigator.mediaDevices
      .getUserMedia(constraints)
      .then((audioStream) => {
        stream.addTrack(audioStream.getTracks()[0]); // joint the two streams
        rec = RecordRTC(stream, {
          videoBitsPerSecond: 6000000,
          bitsPerSecond: 40000000,
          bitrate: 12800000,
          type: "video",

          mimeType: "video/webm;codecs=h264",
        });

        rec.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };

        record = rec;
        rec.startRecording();
      })
      .catch((err) => {
        console.log(err.name, err.message);
      });
  };

  const stopRecording = async (e) => {
    if (record) {
      // inputVideoRef.current.srcObject.getTracks().forEach((track) => {
      //   console.log(track)

      //   track.stop();
      // });

      record.stopRecording(function (blobURL) {
        const getBlob = record.getBlob();

        let reader = new FileReader();
        reader.readAsDataURL(getBlob);

        // reader.onloadend = function () {
        //   let base64data = reader.result;

        //   localStorage.setItem("base64_video", JSON.stringify(base64data));
        // };
        localStorage.setItem("recording", JSON.stringify(blobURL));
      });
      props.recordClick();
    }
  };
  return (
    <Box
      className="App"
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <video id="main_video" autoPlay ref={inputVideoRef} playsInline hidden />
      <canvas
        ref={canvasRef}
        className="canvas"
        width={videoWidth}
        height={videoHeight}
      />
      {btnRec === true && (
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ position: "absolute", bottom: 0, width: "100%" }}
        >
          <Button
            variant="contained"
            className="rec-btn"
            size="large"
            startIcon={!recStart ? <PlayArrow /> : <Stop />}
            id="start_recording"
            onClick={!recStart ? startRecording : stopRecording}
          >
            {!recStart ? "Start Recording" : "Stop Recording"}
          </Button>
          {/* <Button
          variant="contained"
          className="rec-btn"
          size="large"
          startIcon={<Stop />}
          id="stop_recording"
          onClick={stopRecording}
        >
          Stop Recording
        </Button> */}
        </Stack>
      )}
    </Box>
  );
}

export default VideoCam;
