import axios from "axios";

let BASE_URL = process.env.REACT_APP_API_STAGING_URL;

// 1. Login Api
export const loginApi = async (payload) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/login`, payload, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 2. Change Password
export const changePasswordApi = async (payload) => {
  let bearerToken = "Bearer " + localStorage.getItem("token");
  let headers = {
    Authorization: bearerToken,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/change-password`, payload, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 3. Forgot Password (w/ email)
export const forgotPasswordApi = async (payload, headers) => {
  return axios
    .post(`${BASE_URL}/forgot-password`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 4. Get Candidate List
export const getCandidateListApi = async (payload) => {
  let token = localStorage.getItem("token") || localStorage.getItem("candidate_token");
  let bearerToken = "Bearer " + token;
  let headers = {
    Authorization: bearerToken,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .get(`${BASE_URL}/candidate`, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 5. Get Roles List
export const getRolesApi = async (payload, headers) => {
  return axios
    .get(`${BASE_URL}/masters/roles`, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 6. Logout
export const logoutApi = async (payload, flag) => {
  let tokenFromStorage =
    flag == "admin" ? localStorage.getItem("token") : localStorage.getItem("candidate_token");
  let bearerToken = "Bearer " + tokenFromStorage;
  let headers = {
    Authorization: bearerToken,
  };
  return axios
    .post(`${BASE_URL}/logout`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 7. Candidate Login
export const candidateLoginApi = async (payload) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/validate/url`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 8. Create a new Role
export const createRoleApi = async (payload) => {
  let bearerToken = "Bearer " + localStorage.getItem("token");
  let headers = {
    Authorization: bearerToken,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/masters/roles`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 9. Create Candidate from business Admin
export const createCandidateApi = async (payload) => {
  let bearerToken = "Bearer " + localStorage.getItem("token");
  let headers = {
    Authorization: bearerToken,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/candidate`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 10. Update Candidate profile
export const updateCandidateApi = async (payload) => {
  let bearerToken = "Bearer " + localStorage.getItem("candidate_token");
  let headers = {
    Authorization: bearerToken,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/update-profile`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 11. Reset Password
export const resetPasswordApi = async (payload, reset_token) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/reset-password/${reset_token}`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// 12. upload video
export const uploadVideoApi = async (payload) => {
  let bearerToken = "Bearer " + localStorage.getItem("candidate_token");
  let headers = {
    Authorization: bearerToken,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${BASE_URL}/upload-video`, payload, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};