import React, { useEffect, useState } from "react";
// import VideoCam from '../../../components/VideoCam'
import VideoCam from "../../../components/VideoCam";

import { Box, Button, Stack, Backdrop, CircularProgress } from "@mui/material";
import { Stop } from "@mui/icons-material";

function RecordingVideo(props) {
  function continueNext() {
    props.recordClick();
  }

  const [businessLogoImage, setBusinessLogoImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(!loading);
  };

  useEffect(() => {
    setLoading(true);
    let bsLogo = localStorage.getItem("business_logo");


    let videoBg = JSON.parse(localStorage.getItem("business"));


    // let businessLogoUrl = process.env.REACT_APP_BUSINESS_LOGO_API + (videoBg.businesses ? videoBg.businesses.video_template : bsLogo);
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.alt = "business_image";
    // img.src = "https://images.pexels.com/photos/1435752/pexels-photo-1435752.jpeg?auto=compress&cs=tinysrgb&w=800"
    
    img.src = videoBg ? process.env.REACT_APP_BUSINESS_LOGO_API + videoBg?.video_template : bsLogo
    setBusinessLogoImage(img);
    setLoading(false);
  }, []);

  return (
    <Box sx={{ p: 3, pb: 0, height: "100%", position: "relative" }}>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (

        <>{businessLogoImage !== null && <VideoCam recordClick={continueNext} bg={businessLogoImage} />}</>
      )}

      {/* <video
				width="100%"
				height="100%"
				controls
				style={{ borderRadius: "12px", overflow: "hidden", backgroundColor: "#000" }}
			>
				<source
					src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"
					type="video/mp4"
				/>
			</video>
			<Stack
				direction="row"
				justifyContent="center"
				spacing={2}
				sx={{ position: "absolute", bottom: 16, left: 0, width: "100%" }}
			>
				<Button
					variant="contained"
					className="rec-btn"
					size="large"
					startIcon={<Stop />}
					id="stop_recording"
					onClick={continueNext}
				>
					Start Recording
				</Button>
			</Stack> */}
    </Box>
  );
}

export default RecordingVideo;
