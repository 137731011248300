import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import CandidateInformation from "../candidate/verification/Information";
// import ShootVideo from "../candidate/verification/ShootVideo";
import RecordingVideo from "../candidate/verification/shoot-video/RecordingVideo";
import RecordingInformation from "../candidate/verification/shoot-video/RecordingInformation";
import PreviewUpload from "../candidate/verification/PreviewUpload";
import Status from "../candidate/verification/Status";

import {
  Box,
  Stepper,
  Step,
  StepButton,
  Paper,
  useMediaQuery,
  Container,
  Grid,
  Drawer,
  Fab,
  IconButton,
} from "@mui/material";
import { QuestionMark, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const steps = ["Information", "Shoot Video", "Preview & Upload", "Status"];

function CandidateVerification() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});
  // setCompleted
  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };

  // media query
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const stepperScroll = {
    ...(md && { overflowX: "auto", whiteSpace: "nowrap" }),
  };
  const stepperGutters = {
    ...(xs && { paddingTop: "10px", paddingBottom: "10px" }),
    ...(sm && { paddingTop: "20px", paddingBottom: "20px" }),
  };

  // media query

  // on click info drawer open close
  const [openInfo, setOpenInfo] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenInfo(newOpen);
  };

  const fabStyle = {
    position: "fixed",
    bottom: 30,
    right: 30,
  };

  // mobile media query
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const candidateToken = localStorage.getItem("candidate_token");
    console.log(candidateToken);
    if (!candidateToken) {
      navigate("/");
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper square elevation={0} className="stepper-card sticky-div">
        {/* <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span> */}
        <Container maxWidth="lg" sx={{ ...stepperScroll }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index} completed={completed[index]}>
                <StepButton
                  color="primary"
                  onClick={handleStep(index)}
                  sx={{ m: 0, ...stepperGutters, px: 1 }}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Container>
      </Paper>

      {/* step 1 */}
      {activeStep === 0 ? (
        <CandidateInformation handleClick={handleNext} />
      ) : (
        ""
      )}

      {/* step 2 */}
      {activeStep === 1 ? (
        <Container maxWidth="false" sx={{ px: "0 !important" }}>
          <Box className="height-vh">
            <Grid container spacing={0} sx={{ height: "100% " }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={8}
                xl={9}
                sx={{ height: "100%" }}
              >
                <RecordingVideo recordClick={handleNext} />
              </Grid>

              {!isMobile ? (
                <React.Fragment>
                  {/* video cam Information for tab-desktop */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    xl={3}
                    sx={{ height: "100%" }}
                  >
                    <RecordingInformation />
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* video cam Information for mobile */}
                  <Fab
                    color="primary"
                    size="medium"
                    sx={{ ...fabStyle }}
                    onClick={toggleDrawer(true)}
                  >
                    <QuestionMark />
                  </Fab>
                  <Drawer
                    anchor="right"
                    open={openInfo}
                    onClose={toggleDrawer(false)}
                  >
                    <IconButton
                      onClick={toggleDrawer(false)}
                      sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        zIndex: 1,
                      }}
                    >
                      <Close />
                    </IconButton>
                    <RecordingInformation />
                  </Drawer>
                </React.Fragment>
              )}
            </Grid>
          </Box>
        </Container>
      ) : (
        // <React.Fragment>
        //   <RecordingVideo recordClick={handleNext} />
        //   <RecordingInformation />
        // </React.Fragment>
        // <ShootVideo recordClick={handleNext} />
        ""
      )}

      {/* step 3 */}
      {activeStep === 2 ? (
        <PreviewUpload uploadVideo={handleNext} retakeVideo={handleBack} />
      ) : (
        ""
      )}

      {/* step 4 */}
      {activeStep === 3 ? <Status /> : ""}
    </Box>
  );
}

export default CandidateVerification;
