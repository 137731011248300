import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/img/logo.png";

import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  Typography,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  ArrowDropDown,
  AccountCircle,
  Password,
  Logout,
} from "@mui/icons-material";
import { logoutApi } from "../apis";

const settings = [
  {
    key: "1",
    name: "Change Password",
    icon: "Password",
    url: "change-password",
  },
  {
    key: "2",
    name: "Log Out",
    icon: "Logout",
    url: "/",
  },
];
export const icons = {
  Password,
  Logout,
};

const changeTitle = () => {
  let pathArr = window.location.href.split("/");
  let pathName = pathArr[pathArr.length - 1];
  let isPathCandidateVideo = pathArr.find((el) => el == "candidate-video");
  if (isPathCandidateVideo == "candidate-video") {
    pathName = "candidate-video";
  }

  if (pathName && pathName == "candidate-verification") {
    let candidate = localStorage.getItem("candidate");
    let business = localStorage.getItem("business");
    let candidateName = candidate
      ? JSON.parse(candidate).first_name +
        " " +
        JSON.parse(candidate).last_name +
        " - " +
        JSON.parse(business).business_name
      : "Candidate Portal";
    return candidateName;
  } else if (pathName == "candidate-video") {
    return "";
  } else {
    let isName = localStorage.getItem("user");
    let name = isName ? JSON.parse(isName).business_name : "Candidate Portal";
    return name || "Business Admin";
  }
};

function Header() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const location = useLocation();

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    setLoading(true);
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
    setLoading(false);
  }, [open]);

  // media query
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const fontSizeSm = {
    ...(sm && {
      fontSize: "1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }),
  };

  // mobile media query
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const title = changeTitle();

  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleLoading = () => {
    setLoading(!loading);
  };

  const [isCandidate, setIsCandidate] = React.useState(false);

  const [adminName, setAdminName] = useState("Admin");
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [logOutMessage, setLogOutMessage] = useState(
    "Logged out successfully."
  );

  async function handleClick(event) {
    event.preventDefault();
    let type = event.target.innerHTML;
    if (type === "Log Out") {
      let payload = {};
      let userId;
      let logoutFlag;
      if (isCandidate) {
        logoutFlag = "candidate";
        userId = JSON.parse(localStorage.getItem("candidate")).id;
      }
      if (!isCandidate) {
        logoutFlag = "admin";
        userId = JSON.parse(localStorage.getItem("user")).id;
      }
      payload.user_id = userId;
      let logoutResp = await logoutApi(payload, logoutFlag);
      let candidateListStatus = logoutResp.status;
      let respMessage = logoutResp.data.message || "Logged out successfully.";
      if (candidateListStatus !== 200) {
        if (candidateListStatus === 401) {
          // alert("Unauthorized");
          if (isCandidate) {
            navigate("/candidate-login");
          }

          navigate("/");
        }
        setIsError(true);
        let errMessage =
          logoutResp.data.message || "Something went wrong, Please try again";
        setErrorMessage(errMessage);
      }
      console.log(isCandidate);
      if (isCandidate) {
        setIsLoggedOut(true);
        setLogOutMessage(respMessage);

        setTimeout(() => {
          localStorage.removeItem("candidate");
          localStorage.removeItem("candidate_token");
          localStorage.removeItem("business");
          localStorage.removeItem("business_logo");
          navigate("/candidate-login");
        }, 1000);
      } else {
        setIsLoggedOut(true);
        setLogOutMessage(respMessage);

        setTimeout(() => {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("business");
          localStorage.removeItem("candidate");
          localStorage.removeItem("candidate_token");
          localStorage.removeItem("business_logo");
          // navigate("/candidate-login");

          navigate("/");
        }, 1000);
      }
    }
    if (type === "Change Password") {
      navigate("/admin-change-password");
    }
  }

  const [businessLogo, setBusinessLogo] = useState("");

  useEffect(() => {
    setLoading(true);
    let pathName = location.pathname.slice(0);
    let currentPath = pathName.split("-")[0];
    if (currentPath == "/candidate") {
      setIsCandidate(true);
    } else {
      let isName = localStorage.getItem("user");
      let name = isName ? JSON.parse(isName).business_name : "Admin";
      let logo = JSON.parse(isName).businesses
        ? JSON.parse(isName).businesses.logo
        : "";
      let logoUrl = process.env.REACT_APP_BUSINESS_LOGO_API + logo;
      setBusinessLogo(logoUrl);
      setAdminName(name);
      setIsCandidate(false);
    }
    setLoading(false);
  }, [location.pathname]);

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <AppBar
          position="sticky"
          color="default"
          className="header"
          elevation={0}
        >
          <Container maxWidth="false">
            <Toolbar disableGutters variant="dense">
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <Box className="logo">
                  <Link
                    to={
                      isCandidate
                        ? "/candidate-verification"
                        : "/admin-candidate-list"
                    }
                  >
                    <img src={logo} alt="" />
                  </Link>
                </Box>
                <Box className="company-logo">
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, ...fontSizeSm }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Box>
              {isLoggedOut ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoggedOut}
                  onClick={handleLoading}
                >
                  <CircularProgress color="inherit" />
                  <Snackbar
                    open={isLoggedOut}
                    autoHideDuration={1000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Alert
                      variant="filled"
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      {logOutMessage}
                    </Alert>
                  </Snackbar>
                </Backdrop>
              ) : (
                <></>
              )}
              {!isCandidate ? (
                <Box sx={{ flexGrow: 0 }}>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    variant="text"
                    startIcon={
                      businessLogo ? (
                        <img
                          src={businessLogo}
                          className="business-logo-list"
                          alt="business_logo"
                        />
                      ) : (
                        <AccountCircle />
                      )
                    }
                    endIcon={<ArrowDropDown />}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {!isMobile ? (
                      <Box component="span">{adminName || "Admin"}</Box>
                    ) : (
                      ""
                    )}
                  </Button>

                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "right top"
                              : "right top",
                        }}
                      >
                        <Paper
                          elevation={3}
                          sx={{
                            border: "1px solid #EDEDED",
                            borderRadius: "6px",
                          }}
                        >
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              {settings.map((setting) => {
                                const Icon = icons[setting.icon];
                                return (
                                  <MenuItem
                                    key={setting.key}
                                    onClick={handleClose}
                                    sx={{ p: 0 }}
                                  >
                                    <Link
                                      to={setting.url}
                                      onClick={(e) => handleClick(e)}
                                      className="setting-dropdown"
                                    >
                                      <Icon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography
                                        textAlign="center"
                                        sx={{ flex: 1, textAlign: "left" }}
                                      >
                                        {setting.name}
                                      </Typography>
                                    </Link>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Box>
              ) : (
                <>
                  <MenuItem key="logout" onClick={handleClose} sx={{ p: 0 }}>
                    <Link
                      to="/candidate-login"
                      onClick={(e) => handleClick(e)}
                      className="setting-dropdown"
                    >
                      {/* <Icon fontSize="small" sx={{ mr: 1 }} /> */}
                      <Typography
                        textAlign="center"
                        sx={{ flex: 1, textAlign: "left" }}
                      >
                        Log Out
                      </Typography>
                    </Link>
                  </MenuItem>
                </>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}

export default Header;
