import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/img/logo.png";
import lottieImage from "../assets/img/video-conference.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginApi } from "../apis";
import * as Yup from "yup";

import {
	Alert,
	Container,
	Grid,
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	FormGroup,
	TextField,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	useMediaQuery,
	Backdrop,
	CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
	const theme = useTheme();
	const navigate = useNavigate();

	// forgot passoword click
	const forgotPassword = () => {
		navigate("/admin-forgot-password");
	};

	// show hide password
	const [values, setValues] = React.useState({
		password: "",
		showPassword: false,
	});

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// mobile media query
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	// form validation
	const validationSchema = Yup.object().shape({
		email: Yup.string().required("Email is required").email("Email is invalid"),
		password: Yup.string()
			.required("Password is required")
			.min(6, "Password must be at least 6 characters")
			.max(20, "Password must not exceed 20 characters"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const [isError, setIsError] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [isAlert, setIsAlert] = React.useState(false);

	const handleLoading = () => {
		setLoading(!loading);
	};

	const handleAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setIsAlert(false);
		setIsError(false);
	};

	const onSubmit = async (data) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("email", data.email);
		formData.append("password", data.password);
		let payload = formData;
		let loginResp = await loginApi(payload);
		let loginStatus = loginResp.status;
		if (loginStatus === 200) {
			
			setIsAlert(true);
			localStorage.setItem("user", JSON.stringify(loginResp.data.data));
			localStorage.setItem("token", loginResp.data.data.token);
			localStorage.setItem("business", JSON.stringify(loginResp.data.data.businesses));

			let errMessage = loginResp.data.message || "Login Successful";
			setAlertMessage(errMessage);

			navigate("/admin-candidate-list");
		} else {
			setIsAlert(true);
			setIsError(true);
			let errMessage = loginResp.data.message || "Something went wrong, Please try again";
			setAlertMessage(errMessage);
		}
		setLoading(false);
	};

	useEffect(() => {
		setTimeout(function () {
			setIsAlert(false);
			setIsError(false);
		}, 5000); //5 Second delay
	}, [isAlert]);

	return (
		<div className={`auth-screen`}>
			{/* className={`${classes.root} auth-screen`} */}
			{loading ? (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}
					onClick={handleLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			) : (
				<Container maxWidth="lg" sx={{ py: 2 }}>
					<Grid
						className="bg-container"
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={isMobile ? 2 : 2}
					>
						<Grid item xs={12} md={7} sx={{ display: { xs: "none", md: "block" } }}>
							<div className="body-bg">
								{/* <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_ihspk1vg.json"  background="transparent"  speed="1"   style={{width: "100%", height: "100%"}} loop autoplay></lottie-player> */}
								<img src={lottieImage} alt="" />
							</div>
						</Grid>
						<Grid item xs={12} md={5}>
							<Card className="custom-card" variant="outlined">
								<CardContent sx={{ padding: "0 !important" }}>
									<Box sx={{ textAlign: "center" }}>
										<img src={logo} alt="" className="logo-image" />
									</Box>
									<Typography variant="h6" sx={{ fontSize: "1.375rem", textAlign: "center" }} gutterBottom>
										Admin Login
									</Typography>
									{isAlert && (
										<Alert
											open={isAlert}
											variant="filled"
											onClose={handleAlert}
											severity={isError ? "error" : "success"}
										>
											{alertMessage}
										</Alert>
									)}
									<div className="auth-form">
										<Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
											<FormGroup sx={{ mb: 4 }}>
												<TextField
													label="Email"
													placeholder="john@mail.com"
													type="email"
													{...register("email")}
													error={errors.email ? true : false}
												/>
												<Typography sx={{ mt: 0.5 }} variant="inherit" className="error">
													{errors.email?.message}
												</Typography>
											</FormGroup>
											<FormGroup>
												<FormControl variant="outlined">
													<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
													<OutlinedInput
														id="outlined-adornment-password"
														type={values.showPassword ? "text" : "password"}
														onChange={handleChange("password")}
														{...register("password")}
														error={errors.password ? true : false}
														endAdornment={
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={handleClickShowPassword}
																	onMouseDown={handleMouseDownPassword}
																	edge="end"
																	color="primary"
																>
																	{values.showPassword ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														}
														label="Password"
													/>
													<Typography sx={{ mt: 0.5 }} variant="inherit" className="error">
														{errors.password?.message}
													</Typography>
												</FormControl>
											</FormGroup>

											<Box sx={{ mt: 1 }}>
												<Button
													variant="text"
													size="small"
													color="secondary"
													sx={{ textTransform: "inherit" }}
													onClick={forgotPassword}
												>
													Forgot Password?
												</Button>
											</Box>
											<Box sx={{ mt: 4 }}>
												<Button variant="contained" disableElevation size="large" sx={{ width: "100%" }} type="submit">
													Continue
												</Button>
											</Box>
										</Box>
									</div>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			)}
		</div>
	);
}

export default Login;
