import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  IconButton,
  FormGroup,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Avatar,
  Drawer,
  useMediaQuery,
  Autocomplete,
  createFilterOptions,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Close, Person, Add, SettingsRemoteOutlined } from "@mui/icons-material";
import { getRolesApi, createCandidateApi, createRoleApi } from "../apis";

const filter = createFilterOptions();
// const role = [{ title: "Admin" }, { title: "Super Admin" }, { title: "Candidate" }];
function CreateProfile() {
  const [open, setOpen] = React.useState(false);

  let [profileImageErrorFlag, setProfileImageErrorFlag] = useState(false);
  let [firstNameErrorFlag, setFirstNameErrorFlag] = useState(false);
  let [lastNameErrorFlag, setLastNameErrorFlag] = useState(false);
  let [emailErrorFlag, setEmailErrorFlag] = useState(false);
  let [phoneErrorFlag, setPhoneErrorFlag] = useState(false);
  let [roleErrorFlag, setRoleErrorFlag] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setProfileImageErrorFlag(false);
    setFirstNameErrorFlag(false);
    setLastNameErrorFlag(false);
    setEmailErrorFlag(false);
    setPhoneErrorFlag(false);
    setRoleErrorFlag(false);

    setProfileImageError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setRoleError("");
    setOpen(newOpen);
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const dynamicStyles = {
    ...(xs && { width: "350px" }),
    ...(sm && { width: "457px" }),
  };
  const cardPadding = {
    ...(xs && { padding: "1rem" }),
  };
  const fontSizeXs = {
    ...(xs && { fontSize: "1rem" }),
  };

  const navigate = useNavigate();

  // role
  const [value, setValue] = React.useState(null);

  const [isError, setIsError] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);

  const handleLoading = () => {
    setLoading(!loading);
  };

  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAlert(false);
    setIsError(false);
  };

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [countryCode, setCountryCode] = React.useState(27);
  const [mobile, setMobile] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState("");

  let [profileImageError, setProfileImageError] = useState("");
  let [firstNameError, setFirstNameError] = useState("");
  let [lastNameError, setLastNameError] = useState("");
  let [emailError, setEmailError] = useState("");
  let [phoneError, setPhoneError] = useState("");
  let [roleError, setRoleError] = useState("");

  let [isValidated, setIsValidated] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);
    let errorCount = 0;
    if (firstName.length == 0) {
      setFirstNameError("Please enter valid first name");
      setFirstNameErrorFlag(true);
      errorCount++;
    }
    if (lastName.length == 0) {
      setLastNameError("Please enter valid last name");
      setLastNameErrorFlag(true);
      errorCount++;
    }
    if (email.length == 0) {
      setEmailError("Please enter valid email");
      setEmailErrorFlag(true);
      errorCount++;
    }
    if (mobile.length == 0 || isNaN(mobile)) {
      setPhoneError("Please enter valid phone number");
      setPhoneErrorFlag(true);
      errorCount++;
    }

    if (errorCount > 0) {
      setLoading(false);
      return;
    }

    let mobileData = countryCode + mobile;
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("mobile", mobileData);
    if (profileImageFile != null) {
      formData.append("profile_image", profileImageFile);
    }

    if (!selectedRole || (selectedRole && selectedRole.hasOwnProperty("inputValue"))) {
      // add a new role (if role does not exist)
      let newRoleFormData = new FormData();
      if (!selectedRole || (selectedRole && selectedRole.inputValue == null)) {
        setRoleError("Please enter valid role");
        setRoleErrorFlag(true);
        errorCount++;
        setLoading(false);
        return;
      }

      newRoleFormData.append("name", selectedRole.inputValue);
      let rolePayload = newRoleFormData;

      let newRoleResp = await createRoleApi(rolePayload);
      let newRoleStatus = newRoleResp.status;
      if (newRoleStatus === 200) {
        // setIsAlert(true);
        let roleId = newRoleResp.data.data.id;
        formData.append("designation_id", roleId);
      } else {
        if (newRoleStatus === 401) {
          navigate("/");
        }
        setIsAlert(true);
        setIsError(true);
        let errMessage = newRoleResp.data.message || "Something went wrong, Please try again";
        setAlertMessage(errMessage);
        setLoading(false);
        return;
      }
    } else {
      let findRole = roleDesc.find((el) => el.name == selectedRole.title);
      let newRoleId = findRole.id;
      if (!selectedRole || (selectedRole && selectedRole.title == null)) {
        setRoleError("Please enter valid role");
        setRoleErrorFlag(true);
        errorCount++;
      }
      formData.append("designation_id", newRoleId);
    }

    if (errorCount > 0) {
      setLoading(false);
      return;
    }
    let payload = formData;
    let candidateResp = await createCandidateApi(payload);
    setLoading(true);
    let createCandidateStatus = candidateResp.status;
    if (createCandidateStatus === 200) {
      setIsAlert(true);
      let successMsg = candidateResp.data.message || "Candidate created successfully";
      setAlertMessage(successMsg);
      window.location.reload();
    } else {
      if (createCandidateStatus === 401) {
        navigate("/");
      }
      setIsAlert(true);
      setIsError(true);
      let errMessage = candidateResp.data.message || "Failed to create candidate, Make sure to fill all the fields";
      setAlertMessage(errMessage);
    }
    setLoading(false);
    // toggleDrawer(false);
  };

  const [profileImage, setProfileImage] = React.useState("");
  const [profileImageFile, setProfileImageFile] = React.useState();

  function handleProfileImageUpload(e) {
    const newImage = e.target?.files?.[0];

    setProfileImageFile(newImage);

    if (newImage) {
      setProfileImageErrorFlag(false);
      setProfileImage(URL.createObjectURL(newImage));
    }
  }

  async function getRoles() {
    let payload = {};
    let headers = {};
    let roleList = await getRolesApi(payload, headers);
    let roleResp = roleList.data.data;
    setRoleDesc(roleResp);
    let roles = [];
    roleResp.forEach((eachRole) => {
      roles.push({ title: eachRole.name });
    });
    setRoleList(roles);
  }

  const [roleList, setRoleList] = React.useState([]);
  const [roleDesc, setRoleDesc] = React.useState([]);

  useEffect(() => {
    getRoles();
    setTimeout(function () {
      setIsAlert(false);
      setIsError(false);
    }, 5000); //5 Second delay
  }, [isAlert]);

  return (
    <>
      {/* {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : ( */}
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          startIcon={<Add />}
          sx={{ ml: 2 }}
          onClick={toggleDrawer(true)}
        >
          Add
        </Button>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <Box sx={{ height: "100%" }} component="form" noValidate autoComplete="off">
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                minWidth: "1px",
                ...dynamicStyles,
              }}
              className="xs-drawer"
            >
              {isAlert && (
                <Alert
                  mt={2}
                  variant="filled"
                  open={isAlert}
                  onClose={handleAlert}
                  severity={isError ? "error" : "success"}
                >
                  {alertMessage}
                </Alert>
              )}
              <CardHeader
                sx={{ py: 3, px: 5, ...cardPadding }}
                title={
                  <React.Fragment>
                    <IconButton onClick={toggleDrawer(false)} sx={{ mr: 1 }}>
                      <Close sx={{ color: "#000000" }} />
                    </IconButton>
                    <Typography
                      variant="h5"
                      component="span"
                      sx={{ fontSize: "1.375rem", fontWeight: 700, ...fontSizeXs }}
                    >
                      Create a link to video profile
                    </Typography>
                  </React.Fragment>
                }
              />
              <CardContent
                sx={{
                  flex: "1 1 auto",
                  minHeight: "1px",
                  overflowY: "auto",
                  py: 3,
                  px: 5,
                  ...cardPadding,
                }}
              >
                <FormGroup sx={{ mb: 4 }}>
                  <Avatar
                    src={profileImage}
                    sx={{ width: 90, height: 90, marginLeft: "auto", marginRight: "auto" }}
                    variant="rounded"
                    className="avatar-card"
                  >
                    {/* <img src={pic8} alt="" /> */}
                    <Person sx={{ fontSize: "5rem" }} />
                  </Avatar>
                  <Box sx={{ mt: 3, display: "grid", justifyContent: "center" }}>
                    {profileImageErrorFlag && (
                      <Typography sx={{ ml: 2 }} variant="inherit" className="error">
                        {profileImageError}
                      </Typography>
                    )}
                    <Button variant="text" color="secondary" component="label" sx={{ textTransform: "capitalize" }}>
                      Upload Profile Picture
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          handleProfileImageUpload(e);
                          setProfileImageErrorFlag(false);
                        }}
                      />
                    </Button>
                  </Box>
                </FormGroup>

                <FormGroup sx={{ mb: 4 }}>
                  <TextField
                    label="First Name"
                    placeholder="John"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameErrorFlag(false);
                    }}
                    error={firstNameErrorFlag}
                  />
                  {firstNameErrorFlag && (
                    <Typography sx={{ mt: 0.5 }} variant="inherit" className="error">
                      {firstNameError}
                    </Typography>
                  )}
                </FormGroup>
                <FormGroup sx={{ mb: 4 }}>
                  <TextField
                    label="Last Name"
                    placeholder="Doe"
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameErrorFlag(false);
                    }}
                    error={lastNameErrorFlag}
                  />
                  {lastNameErrorFlag && (
                    <Typography sx={{ mt: 0.5 }} variant="inherit" className="error">
                      {lastNameError}
                    </Typography>
                  )}
                </FormGroup>
                <FormGroup sx={{ mb: 4 }}>
                  <TextField
                    label="Email ID"
                    placeholder="john@mail.com"
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailErrorFlag(false);
                    }}
                    error={emailErrorFlag}
                  />
                  {emailErrorFlag && (
                    <Typography sx={{ mt: 0.5 }} variant="inherit" className="error">
                      {emailError}
                    </Typography>
                  )}
                </FormGroup>
                <FormGroup sx={{ mb: 4 }}>
                  <TextField
                    className="prefix-data"
                    label="Phone Number"
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                      setMobile(e.target.value);
                      setPhoneErrorFlag(false);
                    }}
                    error={phoneErrorFlag}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            className="prefix-outline-none"
                            defaultValue={countryCode}
                            onChange={(e) => setCountryCode(e)}
                          >
                            <MenuItem value={27}>+27</MenuItem>
                            <MenuItem value={28}>+28</MenuItem>
                            <MenuItem value={120}>+120</MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {phoneErrorFlag && (
                    <Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
                      {phoneError}
                    </Typography>
                  )}{" "}
                  {/* {...register('mobilenumber')}
                          error={errors.mobilenumber ? true : false}
                  <Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
                {errors.mobilenumber?.message}
              </Typography> */}
                </FormGroup>
                <FormGroup sx={{ mb: 4 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={selectedRole}
                      onChange={(event, value) => {
                        setSelectedRole(value);
                        setRoleErrorFlag(false);
                      }}
                      // onChange={(event, newValue) => {
                      //   if (typeof newValue === "string") {
                      //     setValue({
                      //       title: newValue,
                      //     });
                      //   } else if (newValue && newValue.inputValue) {
                      //     // Create a new value from the user input
                      //     setValue({
                      //       title: newValue.inputValue,
                      //     });
                      //   } else {
                      //     setValue(newValue);
                      //   }
                      // }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.title);
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="role"
                      options={roleList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.title}</li>}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <TextField {...params} label="Role" error={roleErrorFlag} />}
                    />
                  </FormControl>
                  {roleErrorFlag && (
                    <Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
                      {roleError}
                    </Typography>
                  )}{" "}
                </FormGroup>
              </CardContent>
              <CardActions sx={{ py: 3, px: 5, ...cardPadding }}>
                {loading ? (
                  <Box style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ mt: 1 }} disableShrink />
                  </Box>
                ) : (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={loading}
                      disableElevation
                      size="large"
                      sx={{ width: "100%" }}
                      type="button"
                    >
                      CREATE AND SEND
                    </Button>
                  </Box>
                )}
                {/* <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    sx={{ width: "100%" }}
                    type="submit"
                    onClick={handleSubmit}
                  // onSubmit={handleSubmit}
                  >
                    CREATE AND SEND
                  </Button> */}

                {/* onClick={toggleDrawer(false)} */}
              </CardActions>
            </Card>
          </Box>
        </Drawer>
      </React.Fragment>
      {/* )} */}
    </>
  );
}
export default CreateProfile;
