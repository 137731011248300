import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompanyLogo from "../assets/img/logo-default.jpeg";
import pic2 from "../assets/img/profile-pic/2.png";
import pic3 from "../assets/img/profile-pic/3.png";
import pic4 from "../assets/img/profile-pic/4.png";
import pic5 from "../assets/img/profile-pic/5.png";
import pic6 from "../assets/img/profile-pic/6.png";
import pic7 from "../assets/img/profile-pic/7.png";
import pic8 from "../assets/img/profile-pic/8.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getCandidateListApi } from "../apis";
import CreateProfile from "./CreateProfile";

import {
  Box,
  IconButton,
  Typography,
  Container,
  Avatar,
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { AccountCircle, Email, Call, ContentCopy } from "@mui/icons-material";

function CandidateList() {
  // React.state = {
  //     value: '',
  //     copied: false,
  //   };

  // snackbar toaster drawer

  const navigate = useNavigate();

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [candidateList, setCandidateList] = useState([]);
  const [candidateListOrg, setCandidateListOrg] = useState("");
  const [businessLogo, setBusinessLogo] = useState("");

  const [isCopied, setIsCopied] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleLoading = () => {
    setLoading(!loading);
  };

  const handleCandidateVideo = (url) => {
    let fullUrl =
      process.env.REACT_APP_ENDPOINT +
      "/#/candidate-video/" +
      url +
      "?bs_name=" +
      businessData.business_name;
    window.open(fullUrl, "_blank");
  };

  async function fetchCandidateList() {
    setLoading(true);
    let payload = {};
    let candidateListResp = await getCandidateListApi(payload);
    let candidateListStatus = candidateListResp.status;
    if (candidateListStatus === 200) {
      let candidateResponse = candidateListResp.data.data;
      /*  alphabetically sort candidate list
      candidateResponse.sort(function (a, b) {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });
      */
      // let businessAdmin = JSON.parse(localStorage.getItem("user"));
      // let businessId = businessAdmin.business_id;
      // let list = candidateListResp.data.data;
      // let filteredResp = list.filter(el => el.business_id === businessId);
      // candidateListResp.data.data = filteredResp;
      setCandidateList(candidateListResp.data.data);
      setCandidateListOrg(candidateListResp.data.data);
    } else {
      if (candidateListStatus === 401) {
        navigate("/");
      }
      setIsError(true);
      let errMessage =
        candidateListResp.data.message ||
        "Something went wrong, Please try again";
      setErrorMessage(errMessage);
    }
    setLoading(false);
  }

  const handleMobile = (mobile) => {
    let cc, phone;
    if (mobile.substring(0, 2) == "27" || mobile.substring(0, 2) == "28") {
      cc = mobile.slice(0, 2);
      phone = mobile.slice(2);
      mobile = `+(${cc}) ${phone}`;
    } else if (mobile.substring(0, 3) == "120") {
      cc = mobile.slice(0, 3);
      phone = mobile.slice(3);
      mobile = `+(${cc}) ${phone}`;
    } else {
      cc = 27;
      phone = mobile;
      mobile = `+(${cc}) ${phone}`;
    }
    return mobile;
  };

  const handleSearchResult = async (e) => {
    if (/^\s+$/.test(e.target.value) && e.target.value.length > 1) {
      e.target.value = e.target.value.trim();
    }
    if (e.target.value.length > 0 && e.target.value != " ") {
      let newCandidateList = candidateListOrg.filter(
        (value) =>
          value.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          value.last_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          (
            value.first_name.toLowerCase() +
            " " +
            value.last_name.toLowerCase()
          ).includes(e.target.value.toLowerCase()) ||
          value.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.unique_code
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          value.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.designation.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setCandidateList(newCandidateList);
    } else {
      setCandidateList(candidateListOrg);
    }
  };

  const [businessData, setBusinessData] = React.useState("");

  useEffect(() => {
    setLoading(true);

    let businessDetails = JSON.parse(localStorage.getItem("user"))?.businesses;
    setBusinessData(businessDetails);
    setLoading(false);
    fetchCandidateList();
  }, []);
  const checkTokenData = (cud) => {
    const candidateToken = localStorage.getItem("candidate_token");

    if (candidateToken) {
      setTimeout(() => {
        localStorage.removeItem("candidate");
        localStorage.removeItem("candidate_token");
        localStorage.removeItem("business");
        localStorage.removeItem("business_logo");
        window.open(
          `${process.env.REACT_APP_ENDPOINT}/#/candidate-login?cid=${
            cud || ""
          }`,
          "_blank"
        );
        // navigate(`/candidate-login?cid=${cud}`)
      }, 1000);
    } else {
      window.open(
        `${process.env.REACT_APP_ENDPOINT}/#/candidate-login?cid=${cud || ""}`,
        "_blank"
      );
      // navigate(`/candidate-login?cid=${cud}`)
    }
  };
  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box className="content-starts" sx={{ mt: 3 }}>
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className="search-section"
            >
              <Grid item>
                <Box className="search-candidate">
                  <TextField
                    placeholder="Search by name, email, phone number and code"
                    variant="outlined"
                    color="secondary"
                    onChange={handleSearchResult}
                  />
                  {/* on click add create profile drawer show */}
                  <CreateProfile />
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg" sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              {candidateList &&
                candidateList.length !== 0 &&
                candidateList.map((candidate) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={candidate.id}>
                    <Card
                      className="custom-card"
                      variant="outlined"
                      sx={{ height: "100%", pt: 2 }}
                    >
                      <Box className="position-badge">
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ mb: 0, textAlign: "center" }}
                        >
                          {candidate.designation
                            ? candidate.designation.name
                            : "Not Available"}
                        </Typography>
                      </Box>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ mt: 2, width: 90, height: 90 }}
                            src={
                              candidate.profile_image != ""
                                ? process.env.REACT_APP_IMAGE_PATH +
                                  candidate.profile_image
                                : process.env.REACT_APP_BUSINESS_LOGO_API +
                                  businessData.logo
                            }
                            variant="rounded"
                            className="avatar-card"
                          />
                        }
                        title={
                          <List sx={{ wordBreak: "break-word", mt: 0.5 }}>
                            <ListItem sx={{ p: 0 }}>
                              <ListItemIcon sx={{ minWidth: 35 }}>
                                <AccountCircle color="primary" />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  variant="body2"
                                  component="h5"
                                  sx={{ fontWeight: 500 }}
                                >
                                  {(candidate.first_name || "John") +
                                    " " +
                                    (candidate.last_name || "Doe")}
                                </Typography>
                              </ListItemText>
                            </ListItem>

                            <ListItem sx={{ p: 0 }}>
                              <ListItemIcon sx={{ minWidth: 35 }}>
                                <Email color="primary" />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  className="primary-text"
                                  variant="caption"
                                  component="a"
                                  href={"mailto:" + candidate.email}
                                  style={{ textDecoration: "none" }}
                                >
                                  {candidate.email || "john@doe.com"}
                                </Typography>
                              </ListItemText>
                            </ListItem>

                            <ListItem sx={{ p: 0 }}>
                              <ListItemIcon sx={{ minWidth: 35 }}>
                                <Call color="primary" />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  className="primary-text"
                                  variant="caption"
                                  component="a"
                                  href={"tel:" + candidate.mobile}
                                  style={{ textDecoration: "none" }}
                                >
                                  {candidate.mobile
                                    ? handleMobile(candidate.mobile)
                                    : candidate.mobile || "123456789"}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        }
                      />
                      <CardContent
                        className="candidate-other-details"
                        sx={{ padding: "0 !important" }}
                      >
                        <Box sx={{ p: 2 }}>
                          <List sx={{ p: 0, wordBreak: "break-word" }}>
                            <ListItem sx={{ py: 0.5, px: 0 }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="body2"
                                  component="span"
                                  color="textSecondary"
                                >
                                  Code:{" "}
                                  {candidate.unique_code == null
                                    ? "N/A"
                                    : candidate.unique_code}
                                </Typography>
                                <CopyToClipboard
                                  text={candidate.unique_code}
                                  onCopy={onCopyText}
                                >
                                  <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={handleClick({
                                      vertical: "bottom",
                                      horizontal: "center",
                                    })}
                                  >
                                    <ContentCopy fontSize="inherit" />
                                  </IconButton>
                                </CopyToClipboard>

                                {/* snackbar toaster message */}
                                <Snackbar
                                  anchorOrigin={{ vertical, horizontal }}
                                  open={open}
                                  autoHideDuration={3000}
                                  onClose={handleClose}
                                >
                                  <Alert
                                    onClose={handleClose}
                                    variant="filled"
                                    severity="success"
                                    sx={{ width: "100%" }}
                                  >
                                    Copied
                                  </Alert>
                                </Snackbar>
                              </Box>
                            </ListItem>
                            <ListItem sx={{ py: 0.5, px: 0 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#6F75FF",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  checkTokenData(candidate.unique_code)
                                }
                              >
                                Candidate Profile
                                {/* <a
                                  href={`${process.env.REACT_APP_ENDPOINT
                                    }/#/candidate-login?cid=${candidate.unique_code || ""
                                    }`}
                                  target="_blank"
                                >
                                  proreel.com/protech/
                                  {candidate.unique_code || "123456"}
                                </a> */}
                              </Box>
                              <CopyToClipboard
                                text={`${
                                  process.env.REACT_APP_ENDPOINT
                                }/#/candidate-login?cid=${
                                  candidate.unique_code || ""
                                }`}
                                onCopy={onCopyText}
                              >
                                <IconButton
                                  size="small"
                                  sx={{ ml: 1 }}
                                  onClick={handleClick({
                                    vertical: "bottom",
                                    horizontal: "center",
                                  })}
                                >
                                  <ContentCopy fontSize="inherit" />
                                </IconButton>
                              </CopyToClipboard>
                              {/* snackbar toaster message */}
                              <Snackbar
                                anchorOrigin={{ vertical, horizontal }}
                                open={open}
                                autoHideDuration={3000}
                                onClose={handleClose}
                              >
                                <Alert
                                  onClose={handleClose}
                                  variant="filled"
                                  severity="success"
                                  sx={{ width: "100%" }}
                                >
                                  Copied
                                </Alert>
                              </Snackbar>
                            </ListItem>
                            {candidate.video_url != null && (
                              <>
                                <ListItem sx={{ py: 0.5, px: 0 }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <Typography
                                      variant="body2"
                                      component="a"
                                      onClick={() =>
                                        handleCandidateVideo(
                                          candidate.video_url
                                        )
                                      }
                                      value={}
                                    >
                                      Candidate
                                    </Typography> */}

                                    <a
                                      href={`${process.env.REACT_APP_ENDPOINT}/#/candidate-video/${candidate.video_url}?bs_name=${businessData.business_name}`}
                                      target="_blank"
                                    >
                                      Candidate Video
                                    </a>
                                    <CopyToClipboard
                                      text={`${process.env.REACT_APP_ENDPOINT}/#/candidate-video/${candidate.video_url}?bs_name=${businessData.business_name}`}
                                      onCopy={onCopyText}
                                    >
                                      <IconButton
                                        size="small"
                                        sx={{ ml: 1 }}
                                        onClick={handleClick({
                                          vertical: "bottom",
                                          horizontal: "center",
                                        })}
                                      >
                                        <ContentCopy fontSize="inherit" />
                                      </IconButton>
                                    </CopyToClipboard>
                                    {/* snackbar toaster message */}
                                    <Snackbar
                                      anchorOrigin={{ vertical, horizontal }}
                                      open={open}
                                      autoHideDuration={3000}
                                      onClose={handleClose}
                                    >
                                      <Alert
                                        onClose={handleClose}
                                        variant="filled"
                                        severity="success"
                                        sx={{ width: "100%" }}
                                      >
                                        Copied
                                      </Alert>
                                    </Snackbar>
                                  </Box>
                                </ListItem>
                              </>
                            )}
                          </List>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Container>
          {!candidateList || candidateList.length == 0 ? (
            <Container maxWidth="lg" className="no-data-found" sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Box>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ mb: 0, textAlign: "center" }}
                  >
                    No Record Found
                  </Typography>
                </Box>
              </Grid>
            </Container>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
}

export default CandidateList;
