import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { resetPasswordApi } from "../apis";

import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  FormGroup,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function SetPassword() {
  const theme = useTheme();
  const navigate = useNavigate();

  // show hide passowrd
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // show hide confirm passowrd
  const [valuesConfirm, setValuesConfirm] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleChangeConfirm = (prop) => (event) => {
    setValuesConfirm({ ...valuesConfirm, [prop]: event.target.value });
  };
  const handleClickShowPasswordConfirm = () => {
    setValuesConfirm({
      ...valuesConfirm,
      showPassword: !valuesConfirm.showPassword,
    });
  };
  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  // mobile media query
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  // form validation
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(20, "Password must not exceed 20 characters"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm password does not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [isError, setIsError] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);

  const handleLoading = () => {
    setLoading(!loading);
  };

  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAlert(false);
    setIsError(false);
  };

  const location = useLocation();
  const onSubmit = async (data) => {
    let reset_token = location.search.split("=")[1];

    setLoading(true);
    let formData = new FormData();
    formData.append("new_password", data.password);
    formData.append("confirm_password", data.confirmPassword);
    let payload = formData;

    let resetPasswordResp = await resetPasswordApi(payload, reset_token);
    let loginStatus = resetPasswordResp.status;
    if (loginStatus === 200) {
      setIsAlert(true);
      // navigate("/");
      let errMessage = resetPasswordResp.data.message || "Password reset successfully";
      setAlertMessage(errMessage);
    } else {
      setIsAlert(true);
      setIsError(true);
      let errMessage = resetPasswordResp.data.message || "Something went wrong, Please try again";
      setAlertMessage(errMessage);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(function () {
      setIsAlert(false);
      setIsError(false);
    }, 5000); //5 Second delay
  }, [isAlert]);

  return (
    <div className={`auth-screen`}>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="lg" sx={{ py: 2 }}>
          <Grid
            className="bg-container"
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={isMobile ? 2 : 2}
          >
            <Grid item xs={12} md={5} sx={{ paddingLeft: "0 !important" }}>
              <Card className="custom-card" variant="outlined">
                <CardContent sx={{ padding: "0 !important" }}>
                  <Typography variant="h6" sx={{ fontSize: "1.375rem", textAlign: "center" }} gutterBottom>
                    Set Password
                  </Typography>
                  {isAlert && (
                    <Alert
                      open={isAlert}
                      variant="filled"
                      onClose={handleAlert}
                      severity={isError ? "error" : "success"}
                    >
                      {alertMessage}
                    </Alert>
                  )}
                  <div className="auth-form">
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                      <FormGroup sx={{ mb: 4 }}>
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            id="password"
                            type={values.showPassword ? "text" : "password"}
                            onChange={handleChange("password")}
                            {...register("password")}
                            error={errors.password ? true : false}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  color="primary"
                                >
                                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        <Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
                          {errors.password?.message}
                        </Typography>
                      </FormGroup>
                      <FormGroup sx={{ mb: 4 }}>
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                          <OutlinedInput
                            id="confirmPassword"
                            type={valuesConfirm.showPassword ? "text" : "password"}
                            onChange={handleChangeConfirm("password")}
                            {...register("confirmPassword")}
                            error={errors.confirmPassword ? true : false}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordConfirm}
                                  onMouseDown={handleMouseDownPasswordConfirm}
                                  edge="end"
                                  color="primary"
                                >
                                  {valuesConfirm.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Confirm Password"
                          />
                        </FormControl>
                        <Typography variant="inherit" sx={{ mt: 0.5 }} className="error">
                          {errors.confirmPassword?.message}
                        </Typography>
                      </FormGroup>

                      <Box>
                        <Button variant="contained" disableElevation size="large" sx={{ width: "100%" }} type="submit">
                          CREATE AND CONTINUE
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
export default SetPassword;
