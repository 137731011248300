import React from "react";

import { Typography, Paper } from "@mui/material";

function Footer() {
  return (
    <Paper square sx={{ p: 2, mt: 3 }}>
      <Typography
        variant="p"
        gutterBottom
        component="div"
        sx={{ mb: 0, textAlign: "center" }}
      >
        Copyright © eSoft All Rights Reserved.
      </Typography>
    </Paper>
  );
}

export default Footer;
