import React, { useState } from "react";
import { checkWinner } from "../components/gameComponents/Logic";
import Layout from "../components/gameComponents/Layout";
import { Button } from "@mui/material";

const styles = {
  width: "200px",
  margin: "20px auto",
  display: "flex",
  justifyContent: "space-between",
};

function GameLayout() {
  const [layout, setLayout] = useState(Array(9).fill(null));
  const [xIsNext, setXisNext] = useState(true);
  const winner = checkWinner(layout);

  const handleClick = (i) => {
    const layoutState = [...layout];
    // if user clicks on occupied box or if game is won, then return
    if (winner || layoutState[i]) return;
    // put an x or O in the clicked box
    layoutState[i] = xIsNext ? "X" : "O";
    setLayout(layoutState);
    setXisNext(!xIsNext);
  };
  const handleReset = () => {
    setLayout(Array(9).fill(null));
  };
  return (
    <React.Fragment>
      <Layout boxes={layout} onClick={handleClick} />
      <div style={styles}>
        <p>
          {winner
            ? "Winner: " + winner
            : "Next Player " + (xIsNext ? "X" : "O")}
        </p>
        <Button onClick={() => handleReset()} style={{ color: "black" }}>
          Reset
        </Button>
      </div>
    </React.Fragment>
  );
}

export default GameLayout;
