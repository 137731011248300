import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  FormGroup,
  Avatar,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { Container } from "@mui/system";
import { getRolesApi, updateCandidateApi } from "../../apis";

function CandidateInformation(props) {
  const navigate = useNavigate();
  // media query
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const btnStyles = {
    ...(xs && { width: "100%" }),
    ...(sm && { width: "240px" }),
  };
  const fontSize = {
    ...(xs && { fontSize: "1.25rem" }),
  };

  const [roleList, setRoleList] = React.useState([]);
  let [candidate, setCandidate] = React.useState({});

  async function getCandidate() {
    let candidateData = await JSON.parse(localStorage.getItem("candidate"));
    let businessData = await JSON.parse(localStorage.getItem("business"));
    candidateData.business_name = businessData.business_name;
    candidateData.mobile = candidateData.candidates.mobile;
    return candidateData;
  }

  let [profileImageErrorFlag, setProfileImageErrorFlag] = useState(false);
  let [firstNameErrorFlag, setFirstNameErrorFlag] = useState(false);
  let [lastNameErrorFlag, setLastNameErrorFlag] = useState(false);
  let [emailErrorFlag, setEmailErrorFlag] = useState(false);
  let [phoneErrorFlag, setPhoneErrorFlag] = useState(false);
  let [roleErrorFlag, setRoleErrorFlag] = useState(false);

  const [profileImage, setProfileImage] = React.useState("");
  const [profileImageFile, setProfileImageFile] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [mobile, setMobile] = React.useState();
  const [countryCode, setCountryCode] = React.useState();
  const [selectedRole, setSelectedRole] = React.useState();

  let [profileImageError, setProfileImageError] = useState("");
  let [firstNameError, setFirstNameError] = useState("");
  let [lastNameError, setLastNameError] = useState("");
  let [emailError, setEmailError] = useState("");
  let [phoneError, setPhoneError] = useState("");
  let [roleError, setRoleError] = useState("");

  function handleProfileImageUpload(e) {
    const newImage = e.target?.files?.[0];
    setProfileImageFile(newImage);

    if (newImage) {
      setProfileImage(URL.createObjectURL(newImage));
    }
  }

  async function fetchCandidate() {
    setLoading(true);
    let payload = {};
    let headers = {};
    let roleList = await getRolesApi(payload, headers);
    let roleResp = roleList.data.data;
    setRoleList(roleResp);
    let candidateData = await getCandidate();
    setCandidate(candidateData);
    // set candidate from api
    setFirstName(candidateData.first_name);
    setLastName(candidateData.last_name);
    let phone, cc;
    if (
      candidateData.mobile.substring(0, 2) == "27" ||
      candidateData.mobile.substring(0, 2) == "28"
    ) {
      cc = candidateData.mobile.slice(0, 2);
      phone = candidateData.mobile.slice(2);
    } else if (candidateData.mobile.substring(0, 3) == "120") {
      cc = candidateData.mobile.slice(0, 3);
      phone = candidateData.mobile.slice(3);
    } else {
      cc = 27;
      phone = candidateData.mobile;
    }
    setCountryCode(cc);
    setMobile(phone);
    setEmail(candidateData.email);
    setSelectedRole(candidateData.candidates.designation_id);
    setLoading(false);

    if (candidateData.candidates && candidateData.candidates.profile_image) {
      let url =
        process.env.REACT_APP_IMAGE_PATH +
        candidateData.candidates.profile_image;
      setProfileImage(url);
    }
  }

  useEffect(() => {
    fetchCandidate();
  }, []);

  const [isError, setIsError] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);

  const handleLoading = () => {
    setLoading(!loading);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAlert(false);
    setIsError(false);
  };

  async function continueNext() {
    let errorCount = 0;
    if (firstName.length == 0) {
      setFirstNameError("Please enter valid first name");
      setFirstNameErrorFlag(true);
      errorCount++;
    }
    if (lastName.length == 0) {
      setLastNameError("Please enter valid last name");
      setLastNameErrorFlag(true);
      errorCount++;
    }
    if (email.length == 0) {
      setEmailError("Please enter valid email");
      setEmailErrorFlag(true);
      errorCount++;
    }
    if (mobile.length == 0 || isNaN(mobile)) {
      setPhoneError("Please enter valid phone number");
      setPhoneErrorFlag(true);
      errorCount++;
    }

    if (errorCount > 0) {
      setLoading(false);
      return;
    }

    let roleName = roleList.find((el) => el.id == selectedRole);
    let mobileData = countryCode + mobile;
    setLoading(true);
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("mobile", mobileData);
    formData.append("designation_id", roleName.id);
    formData.append("business_id", candidate.business_id);
    if (profileImageFile) {
      formData.append("profile_image", profileImageFile);
    }

    let payload = formData;
    let updateProfileResp = await updateCandidateApi(payload);
    let updateProfileStatus = updateProfileResp.status;
    if (updateProfileStatus === 200) {
      setIsAlert(true);
      let newCandidate = updateProfileResp.data.data;
      Object.keys(newCandidate).forEach((key) => {
        if (newCandidate[key] === null) {
          delete newCandidate[key];
        }
      });
      newCandidate.designation = roleList.find(
        (el) => el.id == newCandidate.designation_id
      );
      candidate.candidates.designation_id = newCandidate.designation.id;
      candidate.mobile = newCandidate.mobile;
      candidate.candidates.mobile = newCandidate.mobile;
      if (newCandidate.profile_image) {
        candidate.candidates.profile_image = newCandidate.profile_image;
      }
      Object.assign(candidate, newCandidate);
      localStorage.setItem("candidate", JSON.stringify(candidate));
      let newProfileImage =
        process.env.REACT_APP_IMAGE_PATH + newCandidate.profile_image;
      setProfileImage(newProfileImage);
      props.handleClick();
    } else {
      if (updateProfileStatus === 401) {
        navigate("/candidate-login");
      }
      setIsAlert(true);
      setIsError(true);
      let errMessage =
        updateProfileResp.data.message ||
        "Something went wrong, Please try again";
      setAlertMessage(errMessage);
      handleSnackbarClick();
    }
    setLoading(false);
  }

  return (
    <>
      {loading && candidate ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={handleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="lg">
          <Box
            className="middle-section"
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              className="custom-card"
              variant="outlined"
              sx={{ width: "100%" }}
            >
              <CardContent sx={{ p: 3 }}>
                {isError && (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                  >
                    <Alert
                      variant="filled"
                      onClose={handleSnackbarClose}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {alertMessage}
                    </Alert>
                  </Snackbar>
                )}
                <Typography variant="h5" sx={{ fontWeight: 700, ...fontSize }}>
                  Verify Your Information
                </Typography>
                <Box
                  sx={{ mt: 6 }}
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                      <FormGroup sx={{ mb: 4 }}>
                        <Avatar
                          sx={{
                            width: 90,
                            height: 90,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src={
                            profileImage
                              ? profileImage
                              : process.env.REACT_APP_IMAGE_PATH +
                                candidate.profile_image
                          }
                          variant="rounded"
                          className="avatar-card"
                        >
                          {/* <img
                            src={process.env.REACT_APP_IMAGE_PATH + candidate.profile_image}
                            alt=""
                          /> */}
                          <Person sx={{ fontSize: "5rem" }} />
                        </Avatar>
                        <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="text"
                            color="secondary"
                            component="label"
                            sx={{ textTransform: "capitalize" }}
                          >
                            Upload Profile Picture
                            <input
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                              onChange={(e) => {
                                handleProfileImageUpload(e);
                                setProfileImageErrorFlag(false);
                              }}
                            />
                          </Button>
                        </Box>
                        {profileImageErrorFlag && (
                          <Typography
                            sx={{ ml: 2 }}
                            variant="inherit"
                            className="error"
                          >
                            {profileImageError}
                          </Typography>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={10}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <TextField
                              label="First Name"
                              defaultValue={candidate.first_name || "John"}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                setFirstNameErrorFlag(false);
                              }}
                              error={firstNameErrorFlag}
                            />
                            {firstNameErrorFlag && (
                              <Typography
                                sx={{ mt: 0.5 }}
                                variant="inherit"
                                className="error"
                              >
                                {firstNameError}
                              </Typography>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <TextField
                              label="Last Name"
                              defaultValue={candidate.last_name || "Doe"}
                              onChange={(e) => {
                                setLastName(e.target.value);
                                setLastNameErrorFlag(false);
                              }}
                              error={lastNameErrorFlag}
                            />
                            {lastNameErrorFlag && (
                              <Typography
                                sx={{ mt: 0.5 }}
                                variant="inherit"
                                className="error"
                              >
                                {lastNameError}
                              </Typography>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <TextField
                              label="Email ID"
                              defaultValue={candidate.email || "john@mail.com"}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailErrorFlag(false);
                              }}
                              error={emailErrorFlag}
                            />
                            {emailErrorFlag && (
                              <Typography
                                sx={{ mt: 0.5 }}
                                variant="inherit"
                                className="error"
                              >
                                {emailError}
                              </Typography>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <TextField
                              className="prefix-data"
                              label="Phone Number"
                              defaultValue={mobile || "82 852 852"}
                              onChange={(e) => {
                                setMobile(e.target.value);
                                setPhoneErrorFlag(false);
                              }}
                              error={phoneErrorFlag}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Select
                                      className="prefix-outline-none"
                                      defaultValue={countryCode}
                                      onChange={(e) =>
                                        setCountryCode(e.target.value)
                                      }
                                    >
                                      <MenuItem value={27}>+27</MenuItem>
                                      <MenuItem value={28}>+28</MenuItem>
                                      <MenuItem value={120}>+120</MenuItem>
                                    </Select>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {phoneErrorFlag && (
                              <Typography
                                variant="inherit"
                                sx={{ mt: 0.5 }}
                                className="error"
                              >
                                {phoneError}
                              </Typography>
                            )}{" "}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <FormControl fullWidth>
                              <InputLabel>Role</InputLabel>
                              <Select
                                defaultValue={
                                  candidate.candidates
                                    ? candidate.candidates.designation_id
                                    : "1"
                                }
                                onChange={(event, value) => {
                                  setSelectedRole(value.props.value);
                                  setRoleErrorFlag(false);
                                }}
                                label="Role"
                              >
                                <MenuItem disabled value="">
                                  Select Role
                                </MenuItem>
                                {roleList &&
                                  roleList.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                      {role.name}
                                    </MenuItem>
                                  ))}
                                {/* <MenuItem value={1}>Admin</MenuItem>
                            <MenuItem value={2}>Super Admin</MenuItem>
                            <MenuItem value={3}>Candidate</MenuItem> */}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <TextField
                              label="Company"
                              defaultValue={
                                candidate.business_name || "Business Name"
                              }
                              disabled
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            disableElevation
                            size="large"
                            sx={{ ...btnStyles }}
                            onClick={continueNext}
                          >
                            CONTINUE
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      )}
    </>
  );
}

export default CandidateInformation;
